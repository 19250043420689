module.exports = [{
      plugin: require('/usr/src/node_modules/gatsby-plugin-glamor/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/usr/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://palmer.net"},
    },{
      plugin: require('/usr/src/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#000","showSpinner":false},
    },{
      plugin: require('/usr/src/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/usr/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-1611517-19"},
    },{
      plugin: require('/usr/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
