import React from 'react';
import { Location } from '@reach/router';
import { Flipper } from 'react-flip-toolkit';

import 'whatwg-fetch';
import './src/reset.css';

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: {
    location: { state, pathname },
  },
}) => {
  // scroll to the top of the page when:
  //  * page is refreshed (prevRouterProps null) since Nav styles cannot be server side rendered
  //  * replacing the navOpen state since this confuses Gatsby's default scroll behavior
  if (
    !prevRouterProps ||
    (prevRouterProps.location.pathname === pathname &&
      (state && state.navOpen === false))
  ) {
    return [0, 0];
  }

  // otherwise default behavior
  return true;
};

export const wrapRootElement = ({ element }) => (
  <>
    <Location>
      {({ location }) => (
        <Flipper
          debug={false}
          flipKey={location.key}
          spring={{ stiffness: 400, damping: 800 }}
        >
          {element}
        </Flipper>
      )}
    </Location>
  </>
);
